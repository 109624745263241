































































import { Component, Vue, Watch } from "vue-property-decorator";

import { articleTypes } from "@/libs/appconst";

@Component
export default class MerchantCategorys extends Vue {
  pagerHeader: any = {
    title: "商家类别列表",
    desc: "商家类别列表",
    breadcrumb: ["商家类别管理", "商家类别列表"]
  };
  loading: boolean = false;
  filter: any = {
    page: 1,
    size: 20,
    type: 2
  };
  tableData: any = {
    items: [],
    totalCount: 0
  };
  categories: any[] = [];
  dateRange: any[] = [];
  editDialog: any = {
    show: false,
    form: {
      type: 2,
      name: "",
      desc: "",
      sort: 0,
      id: 0
    },
    rules: {
      name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      sort: [{ required: true, message: "请输入排序", trigger: "blur" }]
    },
    loading: false
  };
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.getData();
  }
  @Watch("dateRange")
  dateRangeChange(dates: any[]) {
    this.filter.startDate = dates[0];
    this.filter.endDate = dates[1];
  }
  async getData() {
    this.loading = true;
    try {
      let res = await this.$ajax.get(
        "/api/services/app/Category/GetPagedList",
        {
          params: {
            ...this.filter
          }
        }
      );

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
  search() {
    this.filter.page = 1;
    this.getData();
  }
  activated() {
    this.getData();
  }
  created() {
    this.getData();
  }
  openAddCategoryDialog() {
    this.editDialog.show = true;
    this.editDialog.form.name = "";
    this.editDialog.form.sort = "";
    this.editDialog.form.desc = "";
    this.editDialog.form.id = 0;
  }
  openEditCategoryDialog(row: any) {
    this.editDialog.show = true;
    this.editDialog.form.name = row.name;
    this.editDialog.form.sort = row.sort;
    this.editDialog.form.desc = row.desc;
    this.editDialog.form.id = row.id;
  }
  saveCategory() {
    (this.$refs.editDialogForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.editDialog.loading = true;
        try {
          if (this.editDialog.form.id) {
            var res = await this.$ajax.put(
              "/api/services/app/Category/Update",
              this.editDialog.form
            );
          } else {
            var res = await this.$ajax.post(
              "/api/services/app/Category/Create",
              this.editDialog.form
            );
          }
          if (res.data.success) {
            this.$message.success(
              `${this.editDialog.form.id ? "修改" : "添加"}成功`
            );
            this.getData();

            this.editDialog.show = false;
          }
        } catch (error) {}
        this.editDialog.loading = false;
      }
    });
  }

  async deleteCategory(row: any) {
    var result = await this.$confirm("确认要删除该类别吗?");
    if (result == "confirm") {
      let loading = this.$loading({
        text: "请稍后..."
      });
      try {
        var res = await this.$ajax.delete("/api/services/app/Category/Delete", {
          params: {
            id: row.id
          }
        });
        if (res.data.success) {
          this.$notify.success("删除成功");
          this.getData();
        }
      } catch (error) {}
      loading.close();
    }
  }
}
